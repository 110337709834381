'use client';
import {
  LiveKitRoom,
  VideoConference,
  formatChatMessageLinks,
  useToken,
  LocalUserChoices,
  PreJoin,
  useMediaDeviceSelect,
} from '@livekit/components-react';

import {
  DeviceUnsupportedError,
  ExternalE2EEKeyProvider,
  Room,
  RoomConnectOptions,
  RoomOptions,
  VideoCodec,
  VideoPresets,
  LocalAudioTrack,
  setLogLevel,
} from 'livekit-client';

import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { DebugMode } from '../lib/Debug';
import { decodePassphrase, useServerUrl, useOaiData } from '../lib/client-utils';
import { SettingsMenu } from '../lib/SettingsMenu';

const Home: NextPage = () => {
  const router = useRouter();
  type Query = {
    c: string;
    e: string;
    t: string;
  };
  const { c: callback, e: e2ee_key, t: token }: Query = router.query as Query;
  const wss_urlString = process.env.NEXT_PUBLIC_WEBRTC_SERVER || 'wss://webrtc.xyhelper.cn';

  const callbackString = Array.isArray(callback) ? callback[0] : callback;
  const e2ee_keyString = Array.isArray(e2ee_key) ? e2ee_key[0] : e2ee_key;
  const tokenString = Array.isArray(token) ? token[0] : token;
  const onLeave = () => {
    console.log('onLeave----------------------->');
    
    window.location.href = callbackString;
  };
  if (!callback || !e2ee_key || !token) {
    return <div>Invalid URL</div>;
  }
  return (
    <>
      <Head>
        <title>xyhelper-voice</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main data-lk-theme="default">
        {/* <LoadingToken onLeave={onLeave}></LoadingToken> */}
        <ActiveRoom
          token={tokenString}
          e2ee_key={e2ee_keyString}
          url={wss_urlString}
          onLeave={onLeave}
        ></ActiveRoom>
      </main>
    </>
  );
};

export default Home;


type ActiveRoomProps = {
  token: string;
  e2ee_key: string;
  url: string;
  onLeave?: () => void;
};
const ActiveRoom = ({ token, e2ee_key, url, onLeave }: ActiveRoomProps) => {
  console.log('e2ee_key--------->', e2ee_key);
  console.log('token--------->', token);
  console.log('url--------->', url);

  const e2eePassphrase = e2ee_key;
  // const connectToken = '';
  // const e2eePassphrase = '';

  // const e2eePassphrase =
  //   typeof window !== 'undefined' && decodePassphrase(location.hash.substring(1));

  const worker =
    typeof window !== 'undefined' &&
    e2eePassphrase &&
    new Worker(new URL('/js/e2ee-worker.js', window.location.href));
    // new Worker(new URL('livekit-client/e2ee-worker', import.meta.url));

  console.log('worker--------->', worker);
  
  const e2eeEnabled = !!(e2eePassphrase && worker);
  const keyProvider = new ExternalE2EEKeyProvider();

  const roomOptions = React.useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        red: !e2eeEnabled,
      },
      adaptiveStream: false,
      dynacast: true,
      e2ee: e2eeEnabled
        ? {
            keyProvider,
            worker,
          }
        : undefined,
    };
  }, []);
  //   const roomOptions = React.useMemo((): RoomOptions => {
  //     let videoCodec: VideoCodec | undefined = 'vp9' as VideoCodec;
  //     if (e2eeEnabled && (videoCodec === 'av1' || videoCodec === 'vp9')) {
  //       videoCodec = undefined;
  //     }
  //     return {
  //     //   videoCaptureDefaults: {
  //     //     // deviceId: userChoices.videoDeviceId ?? undefined,
  //     //     resolution: hq === 'true' ? VideoPresets.h2160 : VideoPresets.h720,
  //     //   },
  //       publishDefaults: {
  //         dtx: false,
  //         // videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216],
  //         red: !e2eeEnabled,
  //         // videoCodec,
  //       },
  //     //   audioCaptureDefaults: {
  //     //     // deviceId: userChoices.audioDeviceId ?? undefined,
  //     //   },
  //     //   adaptiveStream: { pixelDensity: 'screen' },
  //       dynacast: true,
  //       e2ee: e2eeEnabled
  //         ? {
  //             keyProvider,
  //             worker,
  //           }
  //         : undefined,
  //     };
  //     // @ts-ignore
  //     setLogLevel('debug', 'lk-e2ee');
  //   }, []);

  const room = React.useMemo(() => new Room(roomOptions), []);
  //   await room.localParticipant.setMicrophoneEnabled(true);
  if (e2eeEnabled) {
    keyProvider.setKey(e2eePassphrase);
    // keyProvider.setKey(decodePassphrase(e2eePassphrase));

    room.setE2EEEnabled(true).catch((e) => {
      if (e instanceof DeviceUnsupportedError) {
        alert(
          `You're trying to join an encrypted meeting, but your browser does not support it. Please update it to the latest version and try again.`,
        );
        console.error(e);
      }
    });
  }
  const connectOptions = React.useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);

  return (
    <LiveKitRoom
      room={room}
      token={token}
      serverUrl={url}
      connectOptions={connectOptions}
      video={false}
      audio={true}
      onDisconnected={onLeave}
    >
      <VideoConference
        chatMessageFormatter={formatChatMessageLinks}
        SettingsComponent={SettingsMenu}
      />
      <DebugMode />
    </LiveKitRoom>
  );
};
